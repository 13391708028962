<script lang="ts" setup> 

    const props = defineProps({ 
      hide: {
        type: Boolean,
        default: false
      },
      landing: {
        type: Boolean,
        default: false
      }
    })

    const imageCheck = (img:string) => {
        return img.split('.').pop() || ''
    }

    const route = useRoute()

    const { fetchSettings, settings } = useSettings()
    await fetchSettings()

    const menu = ref(false)
    const submenu = ref(false)
    const dropdown = ref()
    const open = ref()

    const toggle = (uid:string) => {
      if (open.value != uid) {
        open.value = uid
      } else {
        open.value = null
      }
    }

    /* Sub Menu */
    const currentRoute = ref()
    const currentTitle = ref()
    const links = ref([]) 

    const currentSubMenu = () => {
      
      const path = [route.path.split('/').filter((value:string) => value !== '')[0], route.path.split('/').filter((value:string) => value !== '')[1]]
      currentRoute.value = path.join('/').replace(/\/$|$/, '/')
      
      currentTitle.value = undefined
      links.value = []

      settings.value.header_primary_navigation_links.forEach((primary:any) => {
        if(primary && primary.header_secondary_navigation_links) {
          primary.header_secondary_navigation_links.forEach((secondary:any) => {
            if(secondary && secondary.header_secondary_navigation_link_url && (secondary.header_secondary_navigation_link_url.cached_url && secondary.header_secondary_navigation_link_url.cached_url === currentRoute.value)) {
              currentTitle.value = primary.header_primary_navigation_link_title
              links.value = secondary.header_tertiary_navigation_links
            }
          })
        }
      })

    }

    currentSubMenu()

    watch(() => route.path, (value:any) => {
      currentSubMenu()
      menu.value = false
      submenu.value = false
      dropdown.value = null
      open.value = null
    })

</script>

<template>
  <header ref="wrapper" class="fixed xl:relative top-0 left-0 w-full h-auto z-40 border-b border-mystic-200" :class="[links.length > 0 ? 'has-submenu' : '']">

    <!-- Pre Menu -->
    <div v-if="!hide && !landing" class="hidden xl:block bg-mystic-200">
      <div class="container max-w-screen-normal py-1">
        <ul class="flex flex-row gap-2 justify-end items-center">
          <li v-for="link in settings.pre_header_primary_navigation_links" :key="link._uid">
            <nuxt-link :to="`/${link.pre_header_primary_navigation_link_url.cached_url}`" :title="link.pre_header_primary_navigation_link_title" :aria-label="link.pre_header_primary_navigation_link_title" class="text-elephant-950 hover:text-lima-600">
              <span class="font-hass-text text-pxs_mobile md:text-pxs_tablet lg:text-pxs_desktop font-roman">
                {{ link.pre_header_primary_navigation_link_title }}
              </span>
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>

    <!-- Primary Menu -->
    <div class="bg-white-50">
      <div class="container max-w-screen-normal h-10">

        <div class="flex flex-row justify-between items-center h-10">
          <nuxt-link v-if="settings?.dark_logo && settings?.dark_logo?.filename" to="/" title="Home" aria-label="Home">
            <template v-if="imageCheck(settings?.dark_logo?.filename) === 'svg'">
              <NuxtImg
                  v-if="settings?.dark_logo && settings?.dark_logo?.filename"
                  provider="storyblok"
                  width="142"
                  height="48" 
                  :src="settings?.dark_logo?.filename"
                  :alt="settings?.dark_logo?.alt || '#'" 
                  :fetchpriority="'high'"
                  :decoding="'sync'"
                  :loading="'eager'"
                  class="h-5 lg:h-6 w-auto"
                  preload
              />
            </template>
            <template v-else>
              <NuxtImg
                  v-if="settings?.dark_logo && settings?.dark_logo?.filename"
                  provider="storyblok"
                  width="142"
                  height="48" 
                  :sizes="`xs:142px sm:142px md:142px narrow:142px lg:142px xl:142px 1xl:142px normal:142px 2xl:142px 3xl:142px wide:142px`"     
                  quality="75"
                  format="webp"
                  fit="in"
                  :src="settings?.dark_logo?.filename"
                  :alt="settings?.dark_logo?.alt|| '#'" 
                  :fetchpriority="'high'"
                  :decoding="'sync'"
                  :loading="'eager'"
                  class="h-5 lg:h-6 w-auto"
                  preload
              />
            </template>
          </nuxt-link>
          <ul v-if="settings.header_primary_navigation_links && !hide && !landing" class="hidden xl:flex flex-row justify-center items-center gap-4">
            <li v-for="header_primary_navigation_link in settings.header_primary_navigation_links" :key="header_primary_navigation_link._uid" class="relative h-10 w-auto flex flex-col justify-center items-center group" @mouseenter="dropdown = header_primary_navigation_link._uid" @mouseleave="dropdown = null">
              <nuxt-link :to="`/${header_primary_navigation_link.header_primary_navigation_link_url.cached_url}`" :title="header_primary_navigation_link.header_primary_navigation_link_title" :aria-label="header_primary_navigation_link.header_primary_navigation_link_title" class="flex flex-col justify-center items-center h-10 w-auto transition-colors ease-in-out duration-100 font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-elephant-950 hover:text-lima-600 group-hover:text-lima-600">
                <span>{{ header_primary_navigation_link.header_primary_navigation_link_title }}</span>
              </nuxt-link>
              <div v-if="header_primary_navigation_link.header_secondary_navigation_links && header_primary_navigation_link.header_secondary_navigation_links.length > 0" class="absolute top-10 -left-5 min-w-[200px] transition-opacity ease-in-out duration-100" :class="[dropdown === header_primary_navigation_link._uid ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none']">
                <ul class="bg-white-50 p-5 pt-0 mt-0 rounded-bl-1 rounded-br-1 drop-shadow-xl flex flex-col gap-2">
                  <li v-for="sublink in header_primary_navigation_link.header_secondary_navigation_links" :key="sublink._uid">
                    <nuxt-link :to="`/${sublink.header_secondary_navigation_link_url.cached_url}`" :title="sublink.header_secondary_navigation_link_title" :aria-label="sublink.header_secondary_navigation_link_title" class="transition-colors ease-in-out duration-100 font-hass-text text-ps_mobile md:text-ps_tablet lg:text-ps_desktop font-normal text-elephant-950 hover:text-lima-600">
                      {{ sublink.header_secondary_navigation_link_title }}
                    </nuxt-link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <div v-if="!hide && !landing" class="hidden xl:flex flex-wrap justify-end items-center gap-2">
            <div v-if="settings?.telephone_number" class="flex flex-col justify-center items-end gap-0">
              <span class="font-hass-text text-pxs_mobile md:text-pxs_tablet lg:text-pxs_desktop font-roman text-elephant-950">
                Speak to an adviser
              </span>
              <a :href="`tel:${settings?.telephone_number}`" :title="settings?.telephone_number" :aria-label="settings?.telephone_number" class="transition-colors ease-in-out duration-100 font-hass-display text-h6_mobile md:text-h6_tablet lg:text-h6_desktop font-medium text-lima-600">
                {{ settings?.telephone_number }}
              </a>
            </div>
            <img 
              src="~/assets/mcs-certified.svg" 
              alt="MCS Certified"
              width="48"
              height="56"
              class="h-7 w-auto"
              loading="eager"
            >
          </div>

          <template v-if="landing">
            <div class="flex flex-wrap justify-end items-center gap-2">
              <div v-if="settings?.telephone_number" class="flex flex-col justify-center items-end gap-0">
                <span class="font-hass-text text-pxs_mobile md:text-pxs_tablet lg:text-pxs_desktop font-roman text-elephant-950">
                  Speak to an adviser
                </span>
                <a :href="`tel:${settings?.telephone_number}`" :title="settings?.telephone_number" :aria-label="settings?.telephone_number" class="transition-colors ease-in-out duration-100 font-hass-display text-h6_mobile md:text-h6_tablet lg:text-h6_desktop font-medium text-lima-600">
                  {{ settings?.telephone_number }}
                </a>
              </div>
              <img 
                src="~/assets/mcs-certified.svg" 
                alt="MCS Certified"
                width="48"
                height="56"
                class="h-7 w-auto hidden sm:block"
                loading="eager"
              >
            </div>
          </template>

          <div v-if="!hide && !landing" class="flex flex-row justify-center items-center gap-2 xl:hidden">
            <nuxt-link to="/get-a-quote" title="Qoute" aria-label="Qoute" class="transition-colors ease-in-out duration-100 flex flex-col justify-center items-center font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-center bg-lima-600 text-white-50 h-5 md:h-6 w-auto px-4 rounded-full">
              <span>Quote</span>
            </nuxt-link>
            <button @click="menu = !menu" :title="`${[menu ? 'Close Menu': 'Open Menu']}`" :aria-label="`${[menu ? 'Close Menu': 'Open Menu']}`" class="flex flex-col justify-center items-center w-4 h-4">
              <span class="relative w-4 h-4">
                <span class="absolute top-[4px] left-0 w-4 h-[2px] bg-elephant-950 origin-center transition-all [&.isActive]:top-1/2 [&.isActive]:left-0 [&.isActive]:rotate-45" :class="{'isActive': menu}" />
                <span class="absolute top-1/2 left-0 w-4 h-[2px] bg-elephant-950 transition-all [&.isActive]:w-0 [&.isActive]:opacity-0" :class="{'isActive': menu}"/>
                <span class="absolute top-full left-0 -translate-y-[calc(100%_+_2px)] w-4 h-[2px] bg-elephant-950 origin-center transition-all [&.isActive]:top-1/2 [&.isActive]:left-0 [&.isActive]:-translate-y-0  [&.isActive]:-rotate-45" :class="{'isActive': menu}"/>
              </span>
            </button>
          </div>

          <div v-if="hide && settings && settings?.telephone_number" class="hidden md:flex">
            <span class="font-hass-display text-h6_mobile md:text-h6_tablet lg:text-h6_desktop font-medium text-elephant-950">Need Help? Call us on <a :href="`tel:${settings?.telephone_number}`" :title="settings?.telephone_number" :aria-label="settings?.telephone_number" class=" text-lima-600">
                {{ settings?.telephone_number }}
              </a>
            </span>
          </div>

          <NuxtLink v-if="hide" to="/" title="Close" aria-label="Close" class="flex flex-row gap-2 cursor-pointer list-none items-center justify-between">
            <span class="font-hass-text text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-elephant-950">Close</span>
            <span class="w-4 h-4 border-[2px] border-elephant-950 rounded-full flex flex-col justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" height="32" viewBox="0 -960 960 960" width="32" class="w-4 h-4 transition-all rotate-45 ">
                    <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" class="transition-all opacity-100  group-open:opacity-0"/>
                    <path d="M200-440v-80h560v80H200Z" class="transition-all opacity-0 group-open:opacity-100"/>
                </svg>
            </span>
          </NuxtLink>

        </div>

      </div>
    </div>

    <!-- Primary Responsive -->
    <div v-if="!hide" class="fixed top-0 left-0 w-full h-full z-50 bg-elephant-950 transition-all flex xl:hidden flex-col gap-4" :class="[menu ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none']">
      
      <div class="container max-w-screen-normal h-10 border-b border-white-50/10">
        <div class="flex flex-row justify-between items-center h-10">
          <nuxt-link v-if="settings?.light_logo && settings?.light_logo?.filename" to="/" title="Home" aria-label="Home">
            <template v-if="imageCheck(settings?.light_logo?.filename) === 'svg'">
              <NuxtImg
                  v-if="settings?.light_logo && settings?.light_logo?.filename"
                  provider="storyblok"
                  width="142"
                  height="48" 
                  :src="settings?.light_logo?.filename"
                  :alt="settings?.light_logo?.alt || '#'" 
                  :fetchpriority="'high'"
                  :decoding="'sync'"
                  :loading="'eager'"
                  class="h-5 lg:h-6 w-auto"
                  preload
              />
            </template>
            <template v-else>
              <NuxtImg
                  v-if="settings?.light_logo && settings?.light_logo?.filename"
                  provider="storyblok"
                  width="142"
                  height="48" 
                  :sizes="`xs:142px sm:142px md:142px narrow:142px lg:142px xl:142px 1xl:142px normal:142px 2xl:142px 3xl:142px wide:142px`"     
                  quality="75"
                  format="webp"
                  fit="in"
                  :src="settings?.light_logo?.filename"
                  :alt="settings?.light_logo?.alt|| '#'" 
                  :fetchpriority="'high'"
                  :decoding="'sync'"
                  :loading="'eager'"
                  class="h-5 lg:h-6 w-auto"
                  preload
              />
            </template>
          </nuxt-link>
          <div class="flex flex-row justify-center items-center gap-2 xl:hidden">
            <button @click="menu = !menu" :title="`${[menu ? 'Close Menu': 'Open Menu']}`" :aria-label="`${[menu ? 'Close Menu': 'Open Menu']}`" class="flex flex-col justify-center items-center w-4 h-4">
              <span class="relative w-4 h-4">
                <span class="absolute top-[4px] left-0 w-4 h-[2px] bg-white-50 origin-center transition-all [&.isActive]:top-1/2 [&.isActive]:left-0 [&.isActive]:rotate-45" :class="{'isActive': menu}" />
                <span class="absolute top-1/2 left-0 w-4 h-[2px] bg-white-50  transition-all [&.isActive]:w-0 [&.isActive]:opacity-0" :class="{'isActive': menu}"/>
                <span class="absolute top-full left-0 -translate-y-[calc(100%_+_2px)] w-4 h-[2px] bg-white-50 origin-center transition-all [&.isActive]:top-1/2 [&.isActive]:left-0 [&.isActive]:-translate-y-0  [&.isActive]:-rotate-45" :class="{'isActive': menu}"/>
              </span>
            </button>
          </div>
        </div>
      </div>

      <div class="container max-w-screen-normal flex flex-col gap-4">

        <ul v-if="settings.header_primary_navigation_links" class="flex flex-col gap-2">
          <li v-for="header_primary_navigation_link in settings.header_primary_navigation_links" :key="header_primary_navigation_link._uid" class="relative flex flex-col gap-2">
            
            <nuxt-link :to="`/${header_primary_navigation_link.header_primary_navigation_link_url.cached_url}`" :title="header_primary_navigation_link.header_primary_navigation_link_title" :aria-label="header_primary_navigation_link.header_primary_navigation_link_title" class="flex flex-col justify-center items-start min-h-4 w-auto mr-8 transition-colors ease-in-out duration-100 | font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-medium text-white-50">
              <span>{{ header_primary_navigation_link.header_primary_navigation_link_title }}</span>
            </nuxt-link>

            <template v-if="header_primary_navigation_link.header_secondary_navigation_links && header_primary_navigation_link.header_secondary_navigation_links.length > 0">
              <button @click="toggle(header_primary_navigation_link._uid)" :title="`${[open === header_primary_navigation_link._uid ? 'Condense' : 'Expand']}`" :aria-label="`${[open === header_primary_navigation_link._uid ? 'Condense' : 'Expand']}`" class="toggle | absolute top-0 left-full -translate-x-full w-4 h-4 | after:content-[''] after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-translate-y-1/2 after:w-2 after:h-2" :class="[open === header_primary_navigation_link._uid ? 'after:rotate-0' : 'after:rotate-180']"></button>

              <transition name="slide">
                <ul v-if="open === header_primary_navigation_link._uid" class="flex flex-col gap-2 mb-2">
                  <li v-for="sublink in header_primary_navigation_link.header_secondary_navigation_links" :key="sublink._uid">
                    <nuxt-link :to="`/${sublink.header_secondary_navigation_link_url.cached_url}`" :title="sublink.header_secondary_navigation_link_title" :aria-label="sublink.header_secondary_navigation_link_title" class="transition-all ease-in-out duration-100 | font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-white-50/50">
                      {{ sublink.header_secondary_navigation_link_title }}
                    </nuxt-link>
                  </li>
                </ul>
              </transition>

            </template>

          </li>
        </ul>

        <ul class="flex flex-col gap-2 py-4 border-y border-white-50/10">
          <li v-for="link in settings.pre_header_primary_navigation_links" :key="link._uid" class="relative flex flex-col gap-2">
            <nuxt-link :to="`/${link.pre_header_primary_navigation_link_url.cached_url}`" :title="link.pre_header_primary_navigation_link_title" :aria-label="link.pre_header_primary_navigation_link_title">
              <span class="transition-all ease-in-out duration-100 | font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-white-50/50">
                {{ link.pre_header_primary_navigation_link_title }}
              </span>
            </nuxt-link>
          </li>
        </ul>

        <nuxt-link to="/get-a-quote" title="Get a Quote" aria-label="Get a Quote" class="transition-colors ease-in-out duration-100 flex flex-col justify-center items-center font-hass-display text-p_mobile md:text-p_tablet lg:text-p_desktop font-roman text-center bg-lima-600 text-white-50 h-5 md:h-6 w-auto px-4 rounded-full">
          <span>Get a Quote</span>
        </nuxt-link>

        <div class="flex flex-row gap-[10px] items-center">
          <a href="https://www.facebook.com/epcimprovements/" title="Facebook" target="_blank">
            <img
              src="~/assets/icon_social-facebook.svg"
              alt="Facebook"
              width="32"
              height="32"
              class="w-4 h-4"
              loading="lazy"
            >
          </a>
          <!-- <a href="#" title="#" target="_blank">
            <img
              src="~/assets/icon_social-twitter.svg"
              alt="#"
              width="32"
              height="32"
              class="w-4 h-4"
              loading="lazy"
            >
          </a> -->
          <a href="https://www.instagram.com/epc_improvements/" title="Instagram" target="_blank">
            <img
              src="~/assets/icon_social-instagram.svg"
              alt="Instagram"
              width="32"
              height="32"
              class="w-4 h-4"
              loading="lazy"
            >
          </a>
          <!-- <a href="#" title="#" target="_blank">
            <img
              src="~/assets/icon_social-youtube.svg"
              alt="#"
              width="32"
              height="32"
              class="w-4 h-4"
              loading="lazy"
            >
          </a> -->
          <a href="https://www.linkedin.com/company/epcimprovements/" title="LinkedIn" target="_blank">
            <img
              src="~/assets/icon_social-linkedin.svg"
              alt="LinkedIn"
              width="32"
              height="32"
              class="w-4 h-4"
              loading="lazy"
            >
          </a>
          <img 
            src="~/assets/mcs-logo.png" 
            alt="MCS Certified"
            width="48"
            height="56"
            class="h-5 w-auto ml-auto"
            loading="lazy"
          >
        </div>

      </div>

    </div>

    <!-- Secondary Menu -->
    <div v-if="links && links.length > 0 && !hide" class="flex bg-white-50 border-t border-mystic-200">
      <div class="container max-w-screen-normal py-1">
        <div class="flex flex-col lg:flex-row lg:gap-4 justify-start lg:justify-between items-start lg:items-center min-h-6">

          <button @click="submenu = !submenu" :title="`${[submenu ? 'Close Menu': 'Open Menu']}`" :aria-label="`${[submenu ? 'Close Menu': 'Open Menu']}`" class="toggle--dark | relative flex flex-col justify-center items-start font-hass-display text-h7_mobile md:text-h7_tablet lg:text-h7_desktop font-medium text-elephant-950 capitalize mr-auto h-6 lg:h-auto w-full lg:w-auto cursor-pointer lg:cursor-text | after:content-[''] lg:after:content-none after:absolute after:top-1/2 after:left-full after:-translate-x-full after:-translate-y-1/2 after:w-4 after:h-4" :class="[submenu ? 'after:rotate-0' : 'after:rotate-180']">
            <span>{{ currentTitle }}</span> 
          </button>
          
          <ul class="flex flex-col lg:flex-row justify-normal lg:justify-end items-start lg:items-center lg:gap-4 overflow-hidden w-full lg:w-auto" :class="[submenu ? 'h-full lg:h-auto pb-1 lg:pb-0' : 'h-0 lg:h-auto pb-0']">
            <li v-for="link in links" :key="link._uid" class="border-t border-mystic-200 lg:border-none py-2 lg:p-0 w-full lg:w-auto">
              <nuxt-link :to="`/${link?.header_tertiary_navigation_link_url?.cached_url}`" :title="link?.header_tertiary_navigation_link_title" :aria-label="link?.header_tertiary_navigation_link_title" class="transition-colors ease-in-out duration-100 font-hass-text text-ps_mobile md:text-ps_tablet lg:text-ps_desktop font-normal text-elephant-950 hover:text-lima-600 group-hover:text-lima-600">
                <span>{{ link?.header_tertiary_navigation_link_title }}</span>
              </nuxt-link>
            </li>
          </ul>

          <nuxt-link to="/get-a-quote" title="Qoute" aria-label="Qoute" class="transition-colors ease-in-out duration-100 hidden xl:flex flex-col justify-center items-center font-hass-text font-roman text-pxs_mobile md:text-ps_tablet lg:text-ps_desktop text-center bg-lima-600 text-white-50 h-5 md:h-6 w-auto px-4 rounded-full">
            <span>Get a Quote</span>
          </nuxt-link>

        </div>
      </div>

    </div>

  </header>
</template>